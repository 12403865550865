import React, { SyntheticEvent, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useMutation } from '@apollo/client';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from '@emotion/styled';
import { Box, Container, Stack, Divider, Snackbar, Typography } from '@mui/material';
import { BasicButton } from '../../components/Button';
import { signupAppleIdState, appleRefreshTokenState } from '../../atoms';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { Color, WebViewEventType } from '../../constants';
import useWebView from '../../hooks/useWebView';
import useKakaoLogin from '../../operations/mutations/kakaoLogin';
import useAppleLogin from '../../operations/mutations/appleLogin';
import Logo from '../../public/Smob_logo_login.svg';
import Kakao from '../../public/kakao_circle.svg';
import Apple from '../../public/apple_circle.svg';
import { BasicInput } from '../../components/Input';
import useInput from '../../hooks/useInput';
import { userVar, isLoggedInVar } from '../../apollo/cache';
import { MUTATION_LOGIN } from '../../apollo/mutations';

const BackNav = dynamic(() => import('../../components/GNB/Back'), { ssr: false });

export default function Home() {
  const router = useRouter();
  const { isIOS, isMobile } = useDeviceDetect();
  const { postMessage, response, type } = useWebView();
  const { appleLogin, error: appleError, getAppleAuth } = useAppleLogin();
  const { kakaoLogin, error: kakaoError, kakaoErrorModalOpen } = useKakaoLogin();
  const [appleId, setAppleId] = useRecoilState(signupAppleIdState);
  const setAppleToken = useSetRecoilState(appleRefreshTokenState);
  const [email, _, emailHandler] = useInput<string>('');
  const [password, __, passwordHandler] = useInput<string>('');
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');

  const [login] = useMutation(MUTATION_LOGIN, {
    variables: { emailId: email, password, isWeb: true },
    onCompleted({ result }) {
      localStorage.setItem('token', result.token);
      userVar(result.user);
      isLoggedInVar(true);
      router.push('/');
    },
    onError(err) {
      setSnackbarMessage(err.message);
      setShowSnackbar(true);
    },
  });

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    login();
  };

  useEffect(() => {
    if (appleId) setAppleId('');
  }, [isIOS]);

  useEffect(() => {
    if (appleError) {
      router.push({ pathname: '/login/branch', query: { login: 'kakao' } });
      return;
    }
    if (kakaoError) {
      router.push({ pathname: '/login/branch', query: { login: 'kakao' } });
      return;
    }
  }, [appleError, kakaoError]);

  useEffect(() => {
    if (type === WebViewEventType.appleLogin) {
      const { code } = response;
      getAppleAuth(code).then((res) => {
        const { email, refresh_token, appleId } = res;
        appleLogin(appleId, email);
        setAppleToken(refresh_token);
      });
    }
  }, [type]);

  return (
    <>
      <BackNav title="LOGIN" home={true} />
      <Container disableGutters sx={{ flex: '1 1 0', p: 3 }}>
        <Stack sx={{ height: 1 }}>
          <Stack pt={4} pb={6}>
            <Image src={Logo} alt="logo" width={126} height={54} />
          </Stack>
          <Box component={'form'} onSubmit={handleSubmit}>
            <Box mb={1.5}>
              <Typography variant="caption" fontWeight={700}>
                이메일 주소
              </Typography>
              <UnderlinedInput value={email} onChange={emailHandler} placeholder="smob@smob.co.kr" />
            </Box>
            <Box>
              <Typography variant="caption" fontWeight={700}>
                비밀번호
              </Typography>
              <UnderlinedInput type="password" value={password} onChange={passwordHandler} placeholder="" />
            </Box>
            <LoginButton>로그인</LoginButton>
            <ExtraBox mt={3}>
              <Typography onClick={() => router.push('login/find-email')} sx={{ cursor: 'pointer' }}>
                이메일 찾기
              </Typography>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Typography onClick={() => router.push('login/find-password')} sx={{ cursor: 'pointer' }}>
                비밀번호 찾기
              </Typography>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Typography onClick={() => router.push('/signup')} sx={{ cursor: 'pointer' }}>
                회원가입
              </Typography>
            </ExtraBox>
          </Box>
          <Stack justifyContent="space-between" flex="1 1 0">
            <Box mt={7} mb={4}>
              <Divider>
                <Typography color={Color.textSecondary2}>간편 로그인</Typography>
              </Divider>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} pt={4}>
                <div onClick={() => kakaoLogin()} style={{ cursor: 'pointer' }}>
                  <Image src={Kakao} alt="kakao_icon" width={56} height={56} />
                </div>
                {isMobile && isIOS && (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (isMobile) return postMessage({ type: WebViewEventType.appleLogin });
                    }}
                  >
                    <Image src={Apple} alt="apple" width={56} height={56} />
                  </div>
                )}
              </Stack>
            </Box>
            <Box pt={4} display={'flex'} justifyContent={'center'}>
              <Typography variant="body2" color={Color.textSecondary2}>
                Apple로 로그인은 앱에서만 가능합니다
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Container>

      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarMessage}
      />
    </>
  );
}

const UnderlinedInput = styled(BasicInput)`
  padding: 0;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${Color.backgroundDivider};
  cursor: text;
  height: 40px;
  font-size: 1rem;
  color: ${Color.textPrimary};
`;

const LoginButton = styled(BasicButton)`
  width: 100%;
  height: 56px;
  background-color: ${Color.buttonPrimary};
  border-radius: 0;
  border: none;
  margin-top: 24px;
  font-size: 1rem;
  letter-spacing: 2px !important;

  .disabled {
    background-color: ${Color.buttonDisabled};
  }
`;

const ExtraBox = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 16px auto 0;
  max-width: 300px;
  height: 30px;

  span {
    color: ${Color.textPrimary};
    cursor: pointer;
  }

  hr {
    border-color: ${Color.backgroundDivider};
  }
`;
