import React from 'react';
import styled from '@emotion/styled';
import { Color } from '../../constants';

export const Container = styled.div<{
  justify?: string;
  grow?: string;
  background?: string;
  align?: string;
  padding?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: ${({ grow = '0' }) => grow};
  padding: ${({ padding = '24px' }) => padding};
  width: 100%;
  height: 100%;
  justify-content: ${({ justify = 'flex-start' }) => justify};
  background-color: ${({ background = 'transparent' }) => background};
  ${({ align = '' }) => `align-items: ${align};`};
`;

export const ContainerFlexGrow = styled.div<{ justify?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  justify-content: ${({ justify = 'center' }) => justify};
`;

export const ContainerRelative = styled.div`
  position: relative;

  .profile_image {
    border-radius: 60px;
    background-color: #ffffff;
  }
`;

export const ContainerWrapper = ({ children }: { children: any }) => {
  return (
    <section style={{ marginBottom: '14px', backgroundColor: Color.background }}>
      <Container>{children}</Container>
    </section>
  );
};

export const Spacer = styled.div<{ width?: string; height?: string }>`
  background: transparent;
  width: ${({ width = '10px' }) => width};
  height: ${({ height = 0 }) => height};
`;

export const GroupContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});
