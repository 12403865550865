import Image from 'next/image';
import styled from '@emotion/styled';
import { Color } from '../../constants';
import { ContainerRelative } from '../Container';
import { defaultFontStyle, fontConfig } from '../T';
import profileCamera from '../../public/profileCamera.svg';

export const styleFeedback = {
  transition: 'transform 100ms, opacity 200ms',
  cursor: 'pointer',
  outline: 'none',
  letterSpacing: '0.5px !important',
};

export const ButtonFeedback = styled.button(({ disabled }) => ({
  ...styleFeedback,
  background: 'none',
  border: 'none',
  pointerEvents: disabled ? 'none' : 'initial',
  margin: 0,
}));

export const BasicButton = styled(ButtonFeedback)({
  ...defaultFontStyle,
  ...fontConfig(16),
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  color: '#ffffff',
  fontWeight: 'bold',
  fontSize: '14px;',
  '&:disabled': {
    border: 'solid 1px #c5c8ce',
    backgroundColor: '#f7f8f9',
    color: '#c5c8ce',
  },
});

export const BlueButton = styled(BasicButton)({
  backgroundColor: Color.blue,
});

export const WhiteButton = styled(BasicButton)({
  color: '#374553',
  border: '1px solid #C5C8CE',
});

export const ProfileImageButton = ({
  onClick,
  src,
  isMobile,
}: {
  isMobile: boolean;
  src: string;
  onClick: () => void;
}) => (
  <ContainerRelative>
    <input
      id="photo_file"
      type={isMobile ? '' : 'file'}
      accept="image/*"
      style={{ display: 'none' }}
      onClick={onClick}
    />
    <label htmlFor="photo_file" style={{ cursor: 'pointer' }}>
      <Image src={src} className="profile_image" width="120" height="120" />
      <div style={{ zIndex: 5, position: 'absolute', bottom: '6px', right: 0 }}>
        <Image unoptimized src={profileCamera} width={32} height={32} alt="camera" />
      </div>
    </label>
  </ContainerRelative>
);
