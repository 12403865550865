import React, { ChangeEvent, ChangeEventHandler, ReactNode } from 'react';
import styled from '@emotion/styled';
import { defaultFontStyle } from '../T';

export const style = {
  ...defaultFontStyle,
  cursor: 'pointer',
  outline: 'none',
  height: '48px',
  margin: '0 0 10px 0',
  padding: '14px 16px 14px 14px',
  borderRadius: '10px',
  border: 'solid 1px #e9ebee',
  backgroundColor: '#ffffff',
  fontSize: '14px',
  letterSpacing: '-0.21px',
  color: '#374553',
  '::placeholder': {
    opacity: 0.4,
  },
  '::WebkitSearchCancelButton': {
    '-webkit-appearance': 'none',
    height: '1em',
    width: '1em',
  },
  width: '100%',
};

export const groupInput = {
  outline: 'none',
  height: '48px !important',
  padding: '14px 16px 14px 14px',
  borderRadius: '10px !important',
  border: 'solid 1px #e9ebee !important',
  'border-top-right-radius': '0 !important',
  'border-bottom-right-radius': '0 !important',
  backgroundColor: '#ffffff',
  fontSize: '14px !important',
  letterSpacing: '-0.21px !important',
  color: '#374553 !important',
  '::placeholder': {
    opacity: '0.4 !important',
  },
  '::WebkitSearchCancelButton': {
    '-webkit-appearance': 'none',
    height: '1em',
    width: '1em',
  },
  '&:focus': {
    'box-shadow': 'none !important',
    outline: '0px !important',
  },
};

export const Input = ({
  placeholder,
  value,
  onChange,
  type,
  length,
}: {
  placeholder?: string;
  value?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  type?: string;
  length?: number;
}) => (
  <BasicInput
    type={type ? type : 'search'}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    maxLength={length ? length : 20}
  ></BasicInput>
);

export const Radio = ({
  text,
  handler,
  value,
  name,
  width,
  checked,
  disabled,
}: {
  text: ReactNode;
  handler: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: any;
  width?: string;
  checked?: boolean;
  disabled?: boolean;
}) => (
  <RadioGroup style={{ width }}>
    <CheckboxInput onChange={handler} type="radio" name={name} value={value} checked={checked} disabled={disabled} />
    <CheckboxLabel>{text}</CheckboxLabel>
  </RadioGroup>
);

export const CheckboxLabel = styled.span(() => ({
  ...defaultFontStyle,
  padding: '0',
  marginLeft: '6px',
  alignSelf: 'center',
}));

export const CheckboxInput = styled.input(({ id }) => ({
  id: id,
}));

export const RadioGroup = styled.label(() => ({
  display: 'inline-flex',
  cursor: 'pointer',
  input: {
    display: 'inline-block',
    width: 18,
    height: 18,
    backgroundClip: 'content-box',
    border: '1px solid #bbbbbb',
    backgroundColor: 'white',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  'input:checked': {
    padding: 2,
    backgroundColor: '#47C886',
  },
}));

export const BasicInput = styled.input(({ disabled, value }) => ({
  ...style,
  pointerEvents: disabled ? 'none' : 'initial',
}));
